import { Badge, Stack, Text, Box, HStack, Button } from "@chakra-ui/react";
import { useState } from "react";
import {  useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { BiReset } from 'react-icons/bi'
import { startCase, round } from 'lodash';

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Loader from "../../../components/Loader";
import { colorMap, payoutStatusMap } from "../../../constants";
import OrderService from '../../../services/createOrderService';
import GridV2 from "../../../utils/agTablesV2";
import SummaryTable from "../SummaryTable";

const PayoutOrdersV2 = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const isLoading = useSelector((state) => state?.order?.loading);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);

    const USformatter = new Intl.NumberFormat('en-US');

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2000,
    };

    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    const getOrderData = async (filters) => {
        filters["orgId"] = orgId;
        filters["type"] = "payout";
        const responseData = await OrderService.getOrder({ filters });
        setSummaryData(responseData.data.data.summaryArray);
        return responseData;
    }

    const summaryColumns = [
        {
            headerName: "Currency",
            field: "fiatTicker",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Total Count",
            field: "count",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sum Prefunding Received (USDC/T)",
            field: "conversionAmount",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sum Processing Fee",
            field: "processingFeesAmount",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sum Net Amount(Fiat)",
            field: "fiatDeliveredToUser",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
    ]

    const columns = [
        {
            headerName: "Order ID TransFi",
            field: "orderId",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => {
                return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>
            },
        },
        {
            headerName: "Order Status",
            field: "status",
            customerVisibility: true,
            enableRowGroup: true,
            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            cellRenderer: ({ value }) => {
                const newStatus = payoutStatusMap[value] || value;
                const color = colorMap[value];
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
            },
        },
        // { headerName: "Order ID Customer", field: "customer.orgId", customerVisibility: false, width: '200' },
        {
            headerName: "Settlement Type", field: "type", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{'Manual'}</Text>
            )
        },
        {
            headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        { headerName: "Sender Email", field: "customer.email", enableRowGroup: true, customerVisibility: true, width: '300' },
        { headerName: "Recipient Name", field: "recipientDetails.firstName", enableRowGroup: true, customerVisibility: true, width: '200' },
        { headerName: "Recipient Email", field: "recipientDetails.recipientEmail", enableRowGroup: true, customerVisibility: true, width: '200' },
        {
            headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Nature of Sender", field: "", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Nature of Recipient", field: "recipientDetails.type", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Jurisdiction of Sender", field: "", enableRowGroup: true, customerVisibility: false, width: '200',
            cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        { headerName: "Jurisdiction of Recipient", field: "userCountry", enableRowGroup: true, customerVisibility: false, width: '200' },
        {
            headerName: "Initiated Date",
            field: "createdAt",
            filterParams: filterParams,
            customerVisibility: true,
            enableRowGroup: false, 
            cellDataType: 'date',
            width: '220',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? moment(value).format("DD-MMM-YYYY @ h:mm A") : "N/A"}</Text>
            ),
        },
        {
            headerName: "Updated Date",
            field: "timestamps.fundSettledAt",
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            customerVisibility: true,
            enableRowGroup: false, 
            cellDataType: 'date',
            width: '220',
            cellRenderer: ({ value }) => (
                <Text>{value ? moment(value).format("DD-MMM-YYYY @ h:mm A") : "N/A"}</Text>
            ),
        },
        {
            headerName: "Crypto Payment Partner", field: "crypto.partner", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Crypto Partner Order ID", field: "crypto.orderId", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? value : 'N/A'}</Text>
            )
        },
        {
            headerName: "Sender's Digital Asset", field: "depositCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Crypto Amount Received By TransFi", field: "depositAmount", aggFunc: 'sum', cellDataType: 'number', filter: 'agNumberColumnFilter', customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },

        {
            headerName: "Crypto Conversion Rate USDT/Crypto", field: "conversionRateToUSDT",  cellDataType: 'number',  filter: 'agNumberColumnFilter', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "USDC/USDT Received By TransFi", field: "conversionAmount",  filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sender's Crypto Wallet Address", field: "walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner", field: "recipientPaymentMethodDetails.paymentGateway", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner Order ID", field: "recipientPaymentMethodDetails.pmId", customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Recipient's Payment Method", field: "paymentName", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        { headerName: "Recipient's Fiat Currency", field: "fiatTicker", customerVisibility: true, width: '200' },
        {
            headerName: "Conversion Rate(Fiat to USD Rate)", field: "conversionRateToUsd",  filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => {
                return <Text>{value ? round(value, 8) : '0' }</Text>
            }
        },
        {
            headerName: "Gross TXN Amount on Fiat if zero forex markup", field: "grossTxnIfZeroForexMarkup",  filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "FX Markup %", field: "fxMarkupPercentage", cellDataType: 'number', customerVisibility: false,  filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value*100)+"%" : '0'}</Text>
            )
        },
        {
            headerName: "Conversion rate Used by TransFi", field: "conversionRate", filter: 'agNumberColumnFilter',cellDataType: 'number', customerVisibility: false,  cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 6) : '0'}</Text>
            )
        },
        {
            headerName: "Gross TXN Amount on Fiat", field: "grossTxnAmountOnFiat", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "TransFi Forex gain USD", field: "transfiForexGainUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees %", field: "processingFees", aggFunc: 'avg', filter: 'agNumberColumnFilter', cellDataType: 'number',  customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value * 100) + '%' : '0'}</Text>
            )
        },
        {
            headerName: "Fixed Fees",
            field: "fixedFeeDetails",
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? value : 'N/A'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount", field: "processingFeesAmount", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount paid from Sub-Account API", field: "amountPaidFromSubAccountApi", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Fiat Delivered To Receiver", field: "fiatDeliveredToUser", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number',  cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees", field: "paymentPartnerFeesFiat1", filter: 'agNumberColumnFilter', cellDataType: 'number',  customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount paid from Sub Account Recheck", field: "amountPaidFromSubAccountRecheck", filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number',  cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        // {
        //     headerName: "Sub-Account Delivery Date", field: "subAmountDeliveryDate", customerVisibility: true, cellRenderer: ({ value }) => (
        //         <Text>{value ? value : 'N/A'}</Text>
        //     )
        // },
        {
            headerName: "GTV", field: "gtv", customerVisibility: false, filter: 'agNumberColumnFilter', cellDataType: 'number', aggFunc: 'sum', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Fiat Delivered To Receiver(In USD)", field: "fiatDeliveredToUserInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount(In USD)", field: "processingFeesAmountInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees(In USD)", field: "paymentPartnerFeesInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum',  cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        leftIcon={<HiOutlineFilter style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={onOpen}
                    >
                        Filter
                    </Button> */}
                </HStack>
                <GridV2 columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} />
                <SummaryTable summaryColumns={summaryColumns} data={summaryData} />
                {/* <OrderFilter isOpen={isOpen} onClose={onClose} type={"payout"} /> */}
            </Box> : <Loader />}
        </Stack>
    );
};

export default PayoutOrdersV2;
