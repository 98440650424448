import React, { useState } from 'react';
import { Box, Flex, Stack, Button, Grid, GridItem, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '../../components/Common/Typography';
import { CustomSelectField, TextField } from '../../components/Common/Input';
import { SelectMenu } from '../../components/Common/InputComponents/InputComponents';
import { createCollectionOrder, setActiveStep } from '../../store/slices/collectMoneySlice';
import { collectionOrderPartialValidationSchema, collectionOrderValidationSchema } from './validationSchema';
import InvoiceUpload from './InvoiceUpload';
import { callApiWithToken } from '../../utils/http_common';
import { FaAsterisk } from 'react-icons/fa';
import AmountInput from './AmountInput';
import { isEmpty } from 'lodash';

const AddInvoice = ({ currencyOptions }) => {
    const toast = useToast();

    const userEmail = useSelector((state) => state?.login?.userDetails?.email)
    const supportedCurrencies = currencyOptions.map(item => ({
        label: `${item.symbol}`,
        value: `${item.symbol}`,
        logo: `${item.logo}`
    }))

    const { data: customerDetails } = useSelector((state) => state.collectMoney.selectedCustomerDetails) || {};
    const userDetails = useSelector((state) => state?.login?.userDetails)

    const [selectedOption, setSelectedOption] = useState(supportedCurrencies?.find(option => option.value === customerDetails?.customerCurrency));
    const [partialPayment, setPartialPayment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFileUploadError, setIsFileUploadError] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const filterCurrencies = currencyOptions.filter(item => item.symbol === customerDetails?.customerCurrency || ["EUR"].includes(item.symbol)).map(item => ({
        label: `${item.symbol}`,
        value: `${item.symbol}`,
        logo: `${item.logo}`
    }))

    const paymentPurposeList = [
        {
            label: "Utility payment",
            value: "Utility payment"
        },
        {
            label: "Tax payment",
            value: "Tax payment"
        },
        {
            label: "Rental payment",
            value: "Rental payment"
        },
        {
            label: "Insurance payment",
            value: "Insurance payment"
        },
        {
            label: "Salary & wages",
            value: "Salary & wages"
        },
        {
            label: "Investment",
            value: "Investment"
        },
        {
            label: "Trade related payment",
            value: "Trade related payment"
        }
    ];

    const role = userDetails?.cxRoles[0];

    const linkValidityList = [{ label: '15 Days', value: '15' }, { label: '30 Days', value: '30' }, { label: '3 Months', value: '90' }]

    const initialValue = {
        currency: selectedOption?.value,
        amount: "",
        paymentPurpose: "",
        linkValidity: "",
        refId: ""
    }

    const currentValidationSchema = partialPayment ? collectionOrderPartialValidationSchema : collectionOrderValidationSchema;

    async function handleFileUpload({ file = {} }) {
        let timestamp = Math.floor(Date.now() / 1000);
        const filename = `invoice_${timestamp}.` + file?.name?.split('.').pop();
        const data = (await callApiWithToken.get(`/transaction/uploadFile?fileName=${filename}&fileType=${file.type}`)) || {};
        const { uploadUrl } = JSON.parse(data?.data?.data);
        try {
            await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file?.type
                },
                body: file,
            });
            return filename;
        } catch (err) {
            console.error('Error uploading to S3:', err);
            return null;
        }
    }

    const handleSubmit = async (values, actions) => {
        try {
            setLoading(true);
            const file = values.invoice;
            let uploadedFileName = null;
            if (file) {
                uploadedFileName = await handleFileUpload({ file });
            }
            const payload = {
                amount: values.amount,
                currency: values.currency,
                linkValidity: values?.linkValidity?.value,
                purposeCode: values?.paymentPurpose?.value,
                referenceId: values.refId,
                firstName: customerDetails?.name,
                country: customerDetails?.country,
                countryLogo: customerDetails?.countryLogo,
                email: customerDetails?.email,
                role,
                invoice: uploadedFileName,
                maker: userDetails?.firstName,
                currencyLogo: selectedOption?.logo,
                clientEmail: userEmail
            }

            if (isEmpty(uploadedFileName)) {
                setIsFileUploadError(true);
                setLoading(false);
                return
            }

            const response = await dispatch(createCollectionOrder(payload));
            if (response?.payload?.data?.status) {
                setLoading(false);
                navigate('/collection/summary');
                dispatch(setActiveStep(0))
            } else {
                toast({
                    title: response?.payload?.error?.message || "Something went wrong",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: "top",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box bg='white' w='100%' py={"20px"}>
            <Typography type='body' weight='medium' color='primary' >
                Invoice Details
            </Typography>

            <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validationSchema={currentValidationSchema}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Box mt={6}>
                            <Flex>
                                <Typography type='description' weight='regular' color='secondary' colorweight='800'>
                                    Invoice Amount
                                </Typography>
                                <FaAsterisk size={7} style={{ marginTop: 2, marginLeft: 2 }} color="#F50100" />
                            </Flex>
                            <AmountInput filterCurrencies={filterCurrencies} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                        </Box>
                        <Grid mt={'12'} templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                            <GridItem maxW={'386px'}>
                                <TextField label="Reference Id" name="refId" placeholder="Enter Reference Id" required={false} />
                            </GridItem>
                            <GridItem maxW={'386px'}>
                                <SelectMenu
                                    label={"Payment purpose"}
                                    isMandatory={true}
                                    options={paymentPurposeList}
                                    name="paymentPurpose"
                                    placeholder="Select"
                                    searchBoxPlaceholder='Search Payment Purpose'
                                    optionBoxPlaceHolder='No purpose added'
                                    isDisabled={false}
                                />
                            </GridItem>
                            {/* Commenting out the validity part */}

                            {/* <GridItem maxW={'386px'}>
                                <SelectMenu
                                    label={"Link Validity"}
                                    isMandatory={true}
                                    options={linkValidityList}
                                    name="linkValidity"
                                    placeholder="Select"
                                    searchBoxPlaceholder='Search Link Validity'
                                    optionBoxPlaceHolder='No purpose added'
                                    isDisabled={!partialPayment}
                                />
                            </GridItem> */}
                            {/* <GridItem>
                                <Center>
                                    <Flex mt={5} mb={5} gap={5} justify={'center'} alignItems={'center'}>
                                        <Checkbox
                                            isChecked={partialPayment}
                                            onChange={() => setPartialPayment(!partialPayment)}
                                        />
                                        <Typography type='body' weight='medium' color='secondary' colorweight='800' >
                                            Allow partial payment
                                        </Typography>
                                    </Flex>
                                </Center>
                            </GridItem> */}
                        </Grid>

                        <InvoiceUpload setIsFileUploadError={setIsFileUploadError} isFileUploadError={isFileUploadError} />

                        <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                            <Button variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"} onClick={() => navigate(-1)}>
                                Back
                            </Button>
                            <Button bg={'#1A4FD6'} type="submit" size={"sm"} p={"9px 16px 9px 16px"}
                                isLoading={loading} _hover={{ bg: '#1A4FD6' }} textColor={'white'}>
                                Confirm
                            </Button>
                        </Stack>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default AddInvoice;