import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoginService from "../../services/loginService";
import { getItem } from "../../utils/local_storage";

const initialState = {
    "loginDetails": {
        loggedIn: false,
        accessToken: '',
        refreshToken: '',
        error: false,
        altmessage: '',
        orgDetails: {}
    },
    "loading": false
};

export const verifyUser = createAsyncThunk(
    "user/verifyUser",
    async ({ accountDetails, email }, { rejectWithValue }) => {
        try {
            const res = await LoginService.verifyUser({ accountDetails, email });
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const check2FA = createAsyncThunk(
    "user/check2FA",
    async (data, { rejectWithValue }) => {
        try {
            const res = await LoginService.check2FA(data);
            return res?.data;
        } catch (e) {
            e.response.data = ({
                ...e?.response?.data,
                status: e?.response?.status
            });
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const singleSignOn = createAsyncThunk(
    "user/verifyUser",
    async ({ token, setCustomer = false, product }, { rejectWithValue }) => {
        try {
            const res = await LoginService.verifyCustomer({ token, setCustomer, product });
            return res?.data;
        } catch (e) {
            e.response.data = ({
                ...e?.response?.data,
                status: e?.response?.status
            });
            return rejectWithValue(e?.response?.data);
        }
    }
)

export const googleLogin = createAsyncThunk(
    "user/verifyUser",
    async ({ token }, { rejectWithValue }) => {
        try {
            const res = await LoginService.googleLogin({ token });
            return res?.data;
        } catch (e) {
            e.response.data = ({
                ...e?.response?.data,
                status: e?.response?.status
            });
            return rejectWithValue(e?.response?.data);
        }
    }
)

export const getUser = createAsyncThunk(
    "user/getUser",
    async (body, { rejectWithValue }) => {
        try {
            const res = await LoginService.getUser();
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const createOrganization = createAsyncThunk(
    "user/createOrganization",
    async (body, { rejectWithValue }) => {
        try {
            const res = await LoginService.createOrganization(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
)

export const createCustomer = createAsyncThunk(
    "user/createCustomer",
    async (body, { rejectWithValue }) => {
        try {
            const res = await LoginService.createCustomer(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
)

export const signOutUser = createAsyncThunk(
    "user/signOutUser",
    async () => {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('country');
        localStorage.removeItem('countryCode');
        window.sessionStorage.clear();
        localStorage.clear();
        return true;
    }
);

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        resetDetails: (state) => {
            state.loginDetails.altmessage = ''
        }
    },
    extraReducers: {
        [verifyUser.pending]: (state, action) => {
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false
                },
                "loading": true
            }
            return newState;
        },
        [verifyUser.fulfilled]: (state, action) => {
            const { status, accessToken, refreshToken, cxId = '', message } = action?.payload?.data || {};
            if (status === "verified") {
                localStorage.setItem('access-token', accessToken);
                localStorage.setItem('refresh-token', refreshToken);
                localStorage.setItem('loggedIn', true);

                state['loginDetails'] = {
                    loggedIn: true,
                    accessToken,
                    refreshToken,
                    created: false
                };
            }
            if (status === "created") {
                state['loginDetails'] = {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false,
                    created: true,
                    message,
                    cxId: cxId
                };
            }
            state['loading'] = false;
        },
        [verifyUser.rejected]: (state, action) => {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('loggedIn');
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: action.payload?.error?.message
                },
                "loading": false
            }
            return newState;
        },
        [getUser.pending]: (state, action) => {
            state['userDetails'] = {
                loading: true
            };
        },
        [getUser.rejected]: (state, action) => {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('loggedIn');
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: action.payload?.error?.message
                },
                "loading": false
            }
            return newState;
        },       
        [getUser.fulfilled]: (state, action) => {
            const { status, customerDetails, orgDetails, environment } = action?.payload?.data || {};
            if (status) {
                state['userDetails'] = {
                    userStatus: status,
                    ...customerDetails
                };
                state['orgDetails'] = orgDetails;
                state['env'] = environment;
                state['loginDetails'] = {
                    loggedIn: true,
                    accessToken: getItem('access-token'),
                    refreshToken: getItem('refresh-token'),
                }
            }
        },
        [signOutUser.fulfilled]: (state, action) => {
            return initialState;
        },
        [createOrganization.pending]: (state, action) => {
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    created: true,
                    error: false,
                    alterror: false
                },
                "loading": true
            }
            return newState;
        },
        [createOrganization.fulfilled]: (state, action) => {
            const { status, message, companyDetails = {}, cxId } = action?.payload?.data || {};
            if (status === "created") {
                state['loginDetails'] = {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false,
                    created: false,
                    alterror: false,
                    altmessage: message,
                };
            }
            if (status === "exists") {
                state['loginDetails'] = {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false,
                    created: false,
                    alterror: false,
                    altmessage: message,
                    exist: true,
                    orgDetails: companyDetails,
                    cxId: cxId
                };
            }
            state['loading'] = false;
        },
        [createOrganization.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occured" };
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    created: false,
                    alterror: message,
                    altmessage: message
                },
                "loading": false
            }
            return newState;
        },
        [createCustomer.pending]: (state, action) => {
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    created: false,
                    error: false,
                    alterror: false
                },
                "loading": true
            }
            return newState;
        },
        [createCustomer.fulfilled]: (state, action) => {
            const { status, message, cxId } = action?.payload?.data || {};
            if (status === "created") {
                state['loginDetails'] = {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false,
                    created: true,
                    message,
                    cxId: cxId
                };
            }
            state['loading'] = false;
        },
        [createCustomer.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occured" };
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    created: false,
                    alterror: message,
                    altmessage: message
                },
                "loading": false
            }
            return newState;
        },
        [check2FA.pending]: (state, action) => {
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false
                },
                "loading": true
            }
            return newState;
        },
        [check2FA.fulfilled]: (state, action) => {
            const { status, cxId = '', message, mfaStatus = false } = action?.payload?.data || {};
            if (status === "created") {
                state['loginDetails'] = {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false,
                    created: true,
                    message,
                    cxId: cxId
                };
            }
            else {
                state['loginDetails'] = {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: false,
                    message,
                    mfaStatus,
                };
            }
            state['loading'] = false;
        },
        [check2FA.rejected]: (state, action) => {
            let newState = {
                "loginDetails": {
                    loggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    error: action.payload?.error?.message
                },
                "loading": false
            }
            return newState;
        }
    },
});

export const { resetDetails } = loginSlice.actions;

const { reducer: loginReducer } = loginSlice;
export default loginReducer;
